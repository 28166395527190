<template>
  <div class="bigDiv">
    <van-overlay :show="ISshow" @click="ISshow = false">
      <div class="wrapper" @click.stop>
        <div class="block" />
      </div>
    </van-overlay>
    <img
      src="https://oss.hshwhkj.com/hshcrm/image/2024/09/13/172619645918827602.png"
      alt=""
      class="bgc"
    />
    <img :src="mag_Url" alt="" class="mag" />
  </div>
</template>

<script>
import { getTxYjQrcode } from "../../api/components";
export default {
  name: "",
  props: {},
  components: {},
  data() {
    return {
      ISshow: true,
      qr_id: "",
      mag_Url: "",
    };
  },
  methods: {
    async getTxQrcode() {
      const { data } = await getTxYjQrcode({ qr_id: this.qr_id });
      console.log(data);
      this.mag_Url = data.data.img;
      this.ISshow = false;
    },
  },
  mounted() {
    this.getTxQrcode();
  },
  created() {
    this.qr_id = this.$route.query.qr_id;
  },
  watch: {},
  computed: {},
  filters: {},
};
</script>

<style scoped lang="less">
.bigDiv {
  width: 100%;
  height: 667px;

  background-color: #f2fff6;
  .bgc {
    width: 375px;
    height: 810px;

    object-fit: contain;
    position: fixed;
    top: 0;
  }
  .mag {
    position: relative;
    width: 200px;
    height: 200px;
    left: 50%;
    top: 43%;
    z-index: 9999;
    transform: translate(-50%);
  }
}
@media screen and (min-width: 750px) {
  .bigDiv {
    width: 750px;
    height: 812px;

    background-size: contain;
    background-repeat: no-repeat;
    margin: 0 auto;
    .bgc {
      width: 750px;
      height: 812px;
      object-fit: contain;
      position: relative;
      top: 0;
    }
    .mag {
      position: relative;
      width: 200px;
      height: 200px;
      left: 50%;
      top: -66%;
      transform: translate(-50%);
    }
  }
}
</style>
